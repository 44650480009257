import { Box, Flex, HStack, Image, Link } from "@chakra-ui/react";
import logoImg from "assets/images/portfolio.png";
import bubbleImg from "assets/images/bubble.png";
import Login from "assets/images/login.png";
import flagEnImg from "assets/images/flag-us.webp";
import flagFrImg from "assets/images/flag-hi.webp";
import enImg from "assets/images/en.png";

import { useTranslation } from "react-i18next";
export function Header() {
  const { t, i18n } = useTranslation("home");

  const switchLanguage = () => {
    i18n.changeLanguage(i18n.language === "en" ? "fr" : "en");
  };
  return (
    <Flex justify={"space-between"}>
      <Link
        href="http://www.lakshm.in/"
      >
        <Image src={logoImg} h={50} />
      </Link>
      <Box>
        <HStack>
          <Image src={bubbleImg} h={10} />
          <Link
            href={
              "mailto:lakshminsurada@gmail.com?subject=Message from portfolio"
            }
            fontWeight={"bold"}
            fontSize={"lg"}
            isExternal>
            {t("hireMe")}
          </Link>
          <Image
            pl={10}
            src={i18n.language === "en" ? enImg : ''}
            h={6}
          />
          <Image
            src={i18n.language === "en" ? flagEnImg : flagFrImg}
            h={10}
            onClick={switchLanguage}
            cursor="pointer"
          />
          <Link href="#">
          <Image pl={10} h={10} src={Login} alt='login'/>
          </Link>
        </HStack>
      </Box>
    </Flex>
  );
}
